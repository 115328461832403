import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
    defaultProjectId,
    setProjectId,
    setMarsProject
} from '../reducers/project-state';

import {defaultProject} from '../lib/marsbox-api';

/* Higher Order Component to get the mars project from cookie
 * @param {React.Component} WrappedComponent: component to render
 * @returns {React.Component} component with hash parsing behavior
 */
const DefaultLoaderHOC = function (WrappedComponent) {
    class DefaultLoaderComponent extends React.Component {
        componentDidMount () {
            const proj = defaultProject();

            if (proj) this.props.setMarsProject(proj.id, proj.uri);
            else this.props.setProjectId(defaultProjectId);
        }
        render () {
            const {
                /* eslint-disable no-unused-vars */
                reduxProjectId,
                setProjectId: setProjectIdProp,
                setMarsProject: setMarsProjectProp,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    {...componentProps}
                />
            );
        }
    }
    DefaultLoaderComponent.propTypes = {
        reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        setMarsProject: PropTypes.func,
        setProjectId: PropTypes.func
    };
    const mapStateToProps = state => {
        return {
            reduxProjectId: state.scratchGui.projectState.projectId
        };
    };
    const mapDispatchToProps = dispatch => ({
        setProjectId: projectId => {
            dispatch(setProjectId(projectId));
        },
        setMarsProject: (id, uri) => {
            dispatch(setMarsProject(id, null, uri));
        }
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(DefaultLoaderComponent);
};

export {
    DefaultLoaderHOC as default
};
